html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "IBM Plex Mono", monospace;
  margin: 0;
  padding: 0;

  font-size: 1.2rem;
}

span {
  color: inherit;
}

.flex-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.red {
  color: #ec0544;
}
.white {
  color: white;
}

.black {
  background-color: #1f1b1c;
}

#Welcome {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100vh;
  color: #fff;
}
header {
  align-self: center;
  padding-top: 5vh;
}

#Hello,
#Hello span {
  font-size: 21rem;
  margin-left: 4vw;
}

main {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

main > .flex-div {
  grid-column: 3 / span 3;
  padding-bottom: 1vh;
}

.flex-div :nth-child(5) {
  display: none !important;
}

.ShortArrow {
  align-self: center;
}

nav {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  transition: 1s;
  height: 6vh;
}
nav ul {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 3vw;
  list-style: none;
  justify-content: flex-end;
  color: #1f1b1c;
}

nav li {
  color: inherit;

  flex-shrink: 0;
}
.Navlink {
  transition: 0.45s ease-out;
  padding-left: 3vw;
}

.Navlink:hover {
  color: #ec0544;
  cursor: pointer;
}

nav ul li:nth-child(5) {
  margin-right: 1rem;
}

nav ul li:first-child {
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 1rem;
  display: none;
}

nav ul li:nth-child(2) {
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 1rem;
}

.Navlink span::after {
  content: "";
  height: 2px;
  display: block;
  width: 0;
  background-color: #ec0544;
  transition: 0.45s ease-out;
}

.selected span::after {
  width: 100%;
}

.selected {
  color: #ec0544;
}

/* AboutMe */
#AboutMe {
  background-color: #1f1b1c;
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #fff;
  min-height: 96vh;
  position: relative;
}

.Me {
  width: 100%;
  height: 100%;
  transition: all 3s;
  padding-left: 4vw;
  left: -80vh;
  opacity: 0.1;
  position: relative;
  transition: all 1.5s;
  padding-right: 3vw;
  z-index: 2;
  max-height: 94vh;
}

#AboutMe h1 {
  font-size: 3.3rem;
}

#summary > p {
  padding-right: 6vw;
  padding-bottom: 2vh;
}

#summary {
  display: flex;
  padding-left: 1vw;
  flex-direction: column;
  justify-content: space-between;
}

#summary > :last-child {
  position: relative;
  padding-top: 2vh;
  color: #1f1b1c;
  z-index: 1;
}
#summary > :last-child::after {
  content: "";
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: -1;
  height: 120%;
}

#MidArrow {
  width: 4vw;
  height: 7vh;
  z-index: 3;
  left: 8vw;
  bottom: -4vh;
  position: absolute;
}

/* NavBar Animation */

.show {
  animation: show 0.5s forwards;
}

.disappear {
  animation: disappear 0.5s 4s forwards;
}

/*  @keyframes show{
   from {
     top: -7vh;
   }
   to {
      top:0
   }
 }

@keyframes disappear {
  to {
    top:-7vh
  }
} 
 */

/*Projects*/

#Projects {
  margin-top: 10vh;
}

#Projects h1 {
  font-size: 3.3rem;
  margin-left: 2vw;
}

.ProjectCard {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  grid-template-areas: "images text";
  color: #fff;
  background-color: #1f1b1c;
  overflow: hidden;
  padding-bottom: 10%;
  padding-top: 5%;
}

.ImagesDiv {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  grid-template-areas: "tall short";
  grid-area: images;
  gap: 0.5vw;
  padding-left: 2vw;
  padding-top: 5%;
}

.ImagesDiv a {
  display: flex;
}

.ImagesDiv a:first-child {
  grid-area: tall;
}
.ImagesDiv a:nth-child(2) {
  grid-area: short;
}

#Projects .ProjectCard:nth-child(odd) {
  grid-template-columns: 0.8fr 1fr;
  grid-template-areas: "text images";
}

#Projects .ProjectCard:nth-child(odd) .ImagesDiv {
  grid-template-columns: 1.2fr 1fr;
  grid-template-areas: "short tall";
}
#Projects .ProjectCard:nth-child(odd) .TextDiv h2 {
  align-self: end;
  width: initial;
}

.ImagesDiv img {
  width: 100%;
}

#Landscape {
  height: 65%;
  align-self: flex-end;
}

.TextDiv {
  margin-top: 8vh;
  display: flex;
  padding: 0 1vw;
  flex-direction: column;
  grid-area: text;
}

.TextDiv h2 {
  font-size: 1.5rem;
  width: 70%;
}

.InnerText {
  padding: 10vw 3vw 0 3vw;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.InnerText p {
  margin-bottom: 5%;
}

.ProjectLink {
  border: #fff solid 0.3px;
  margin-right: 5%;
  padding: 0.4vh 6.3vw 0.4vh 1.8vh;
}

/*Footer*/
.Footer {
  background-color: black;
}
.Footer a,
.Footer p {
  color: white;
}
.PersonalInfo {
  padding: 2% 0 2% 7%;
  display: flex;
  flex-direction: column;
}
/* .PersonalInfo a,p,span, u{
  padding-bottom: 0.3%;
  font-size: 16px;
} */
.Bye {
  margin-top: -20%;
  padding-left: 37%;
  margin-bottom: -1%;
}
.Networks {
  display: flex;
  justify-content: space-between;
  width: 15%;
}
.RightArr {
  width: 6rem;
  margin-left: -8%;
  margin-top: -4%;
}
.linkNet {
  width: 95%;
  margin-left: 15%;
}
.signature {
  padding-bottom: 2%;
  padding-left: 7%;
  font-size: 13px;
}
.Networks a:last-of-type {
  margin-left: 5%;
  margin-top: 1.5%;
}

/*Contact*/
.Contact h1 {
  font-size: 2.8rem;
}
.CenterContact h1:last-child {
  margin-left: 1vw;
}

.Contact {
  display: flex;
  margin-top: 4%;
}
.ContactArr {
  width: 15%;
  margin-top: 4.5%;
}
.CenterContact {
  margin-top: 4%;
  margin-left: 2%;
  display: flex;
}
.RightContact {
  margin-top: 9%;
  margin-left: 8%;
}
.blackFont {
  color: black;
}
.variable {
  display: flex;
}
.variable:first-of-type {
  margin-top: 5%;
}
.mainCode {
  margin-top: 8%;
}
#ContactArrVert {
  display: none;
}
.info {
  margin: 1% 0 1% 3%;
  width: 110%;
}
.ClosingCurly {
  margin: 0% 5% 30% 140%;
}

.buttons {
  text-align: center;
}
.buttons a:hover {
  color: #ec0544;
  transition: 0.6s;
  border-color: #ec0544;
}
