.hamline {
    background-color: #1f1b1c;
    height: 3px;
    width: 23px;    
    margin: 6px 0;
    transition: 1.5s;

}




@media (max-width:650px) {

    nav li:nth-child(3),nav li:nth-child(4),nav li:nth-child(5), nav li:nth-child(6) {
        display: none;
    }

    nav {
        height: 6vh;
    }
    #Welcome {
        grid-template-rows: 0.35fr 1fr;
   
    }


    header {
        align-self: end;
    }
    #Hello, #Hello span {
        font-size: 6rem;
    }
    * {
        font-size: 1rem;
    }
  
    main  .flex-div {
        grid-column: 2 /span 5;
        padding-right: 3vw;
    }
    nav ul li:first-child {
        margin: 0;
        flex-grow: 0;
       
        display: block;
     }
 
     nav ul li:nth-child(2) {
        
        flex-grow: 1;
        margin-right: 9vw;
        text-align: center;
        font-weight: bold;

     }

     nav ul li:nth-child(2) {
      
        margin-left: 0px; 
   }

     
 
      .Navlink {
         color: #1f1b1c;
       
         color: #ec0544;
        
      }

      .SideMenu ul {
        color: #ec0544;
        font-size: 1.3rem;
      }

      .flex-div :nth-child(4) {
        display: none !important;
      }
      .flex-div :nth-child(5) {
        display: inline-block !important;
        align-self: flex-start;
        z-index: 0;
      }
      
      
   
    @media (max-height:667px)
    {
        * {
            font-size: 0.9rem;
        }
        nav li{
                padding: 0.5rem;
              }

            
        
    }

  #summary {
      padding-left: 6vw;
  }

  #MidArrow {
      left: 60%;
      width: 10vw;
      height: 8vh;
      bottom: -7vh;
    }
}

.nav-active {
    color: #fff;
}

nav ul::before{
    background-color: #1f1b1c;
    content: "";
    color: #fff;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 100%;
    z-index: -1;
    transition: 1s;
}



ul.nav-active::before {

    right: 0;
    
}

.ham-active {
    background-color: #fff;
  
}

#line-2.ham-active {
    opacity: 0;
}

#line-3.ham-active {
    transform: rotate(-45deg) translate(5px,-6px);
}

#line-1.ham-active {
    transform: rotate(45deg) translate(6px, 7px);
}

.SideMenu {
    height: 100%;
    width: 100%;
    position: fixed;
    margin-top: 6vh;
    top: 0;
    left:-100%;
    background: #fff;
    z-index: 5;
    transition: 1s;
    display: grid;
    grid-template-rows: 1fr 0.3fr;
}

.SideMenu.active {
    left: 0;
}

.SideMenu  span{
    display: inline-block;
    font-size: 1.8rem;
    
}

.SideMenu ul {
    display: flex;
    padding-top: 8vh;
    flex-direction: column;
    margin-left: 4vw;
    list-style: none;
}

.SideMenu ul > * {
    margin-top: 3vh;
}



.social :nth-child(3), .social :nth-child(2) {
    align-self: start;
margin-left: 10vw;}


@media screen and (max-width:300px) {
    #Hello {
        font-size: 5rem;
    }
}

/*Footer*/
@media (max-width:875px) {
   
.PersonalInfo{
    padding: 2% 0 2% 4%;
    display: flex;
    flex-direction: column;
}
.PersonalInfo a,p{
    padding-top: 2%;
}
.PersonalInfo p:first-of-type{
    padding-top: 8%;
}
.signature{
    padding-bottom: 5%;
    padding-left: 4%;
    font-size: 8px;
}
.Bye{
    margin-top: 0%;
    padding-left: 13%;
    margin-bottom: 3%;
}
.Networks{
    width: 40%;
    margin: 4% 0;
}
.Networks a{
    margin-left: 12%;
}


/*Projects*/
#Projects {
    margin-top: 15%;
}
.ImagesDiv {
    display: flex;
    flex-direction: column;
    padding-left: 2VW;
    padding-top: 5%;
}
.ProjectCard {
    display: flex;
    flex-direction: column;     
    padding-bottom: 20%;
}

.InnerText {
    width: 100%;
    padding: 0;
    margin-top: 8%;
}
.InnerText p{
    width: 100%;
}
.buttons{
    display: flex;
    flex-direction: column;
}
.buttons a{
    text-align: center;
    margin-top: 7%;
    width: 83%;
}
.TextDiv {
    width: 95%;
    margin-left: 3%;
}

/*Contact*/
.CenterContact{
    display: block;
}
.Contact{
    display: block;
}
.ClosingCurly{
    margin: 12% 5% 15% -5.5%;
}
.ContactArr{
    display: none;
}
#ContactArrVert{
    display: block;
    margin-left: 7%;
    width: 10%;
    margin-top: -9%;
}
.info{
    width:95%;
}
}