
/* #Hello {
    border-right: .2rem solid #ec0544;
    white-space: nowrap;
    animation: typing 1.5s steps(10, end),
               caret .35s step-end infinite,
               noCaret 0.1s 1.8s forwards; 
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    
} */

.typed {
    width: 0;
    overflow: hidden;
    display: inline-block;
    margin-right: auto; 
    white-space: nowrap;

    border-right: .2rem solid #ec0544;
    animation: typing 1.5s 0.1s steps(10, end) forwards,
               caret .35s step-end infinite,
               noCaret 0.1s 1.8s forwards; 
   
   
}
.beforeType {
    width: 0;
    overflow: hidden;
    display: inline-block;
    margin-right: auto; 
    white-space: nowrap;
}
#Path_2 {
    transform: "translate(-18.944 -300.456)";
}
.textShowing {
    opacity: 0;
    animation: showContent .5s 2s forwards;
}

.container {
    display: inline-block;
}

@keyframes caret {
    from, to { border-color: transparent }
    50% { border-color: #ec0544}
}


@keyframes typing{
    from {
        width: 0;
    }
    to {
        width:100%;
    }
}

@keyframes noCaret {
    to {
        border-right: none;
    }
}
@keyframes showContent {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

